import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/home";
import AboutPage from "./components/aboutPage";
import { Route, Routes, BrowserRouter, useNavigate } from "react-router-dom";
import ContactPage from "./components/contactPage";
import OurTeamPage from "./components/OurTeamPage";
import AwardsPage from "./components/AwardsPage";
import Payment from "./components/Payment";
import Term_Condition from "./components/Term_Condition";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/team" element={<OurTeamPage />} />
        <Route path="/awards" element={<AwardsPage />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/term_condition" element={<Term_Condition />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
