import React from 'react'
import Header from './Navbar'
import Heading from './Heading'
import Footer from './Footer'

const AwardsPage = () => {
  return (
   <>
<Header/>
<Heading/>
<Footer/>

   </>
  )
}
export default AwardsPage;
