import React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { motion } from "framer-motion";

import cab from "../image/cab.jpeg";
import custom from "../image/custom.jpeg";
import ecommerce from "../image/ecommerce.jpeg";
import education from "../image/education.jpeg";
import fintech from "../image/fintech.jpeg";
import food from "../image/food.jpeg";
import healthcare from "../image/healthcare.jpeg";
import service from "../image/service.jpeg";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
export const ProductCard = () => {
  return (
    <>
      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          x: 1 % 2 === 0 ? 50 : -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0, // Slide in to its original position
          transition: {
            duration: 1, // Animation duration
          },
        }}
        viewport={{ once: true }}
      >
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="10" xl="8" className="text-center">
            <h3 className="mb-4">Products/Services</h3>
            <p className="mb-4 pb-2 mb-md-5 pb-md-0">
              Lorem ipsum dolor sit amet,
            </p>
          </MDBCol>
        </MDBRow>
      </motion.div>

      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          x: 1 % 2 === 0 ? 50 : -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0, // Slide in to its original position
          transition: {
            duration: 1, // Animation duration
          },
        }}
        viewport={{ once: true }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={3}>
              <MDBCard>
                <MDBCardImage src={cab} position="top" alt="..." />
                <MDBCardBody>
                  <MDBCardTitle>Cab App</MDBCardTitle>

                  <p href="#">Rs.25,000</p>
                  <Link to="/payment">
                    <MDBBtn>Pay Now</MDBBtn>
                  </Link>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={2} sm={4} md={3}>
              <MDBCard>
                <MDBCardImage src={custom} position="top" alt="..." />
                <MDBCardBody>
                  <MDBCardTitle>Custom App</MDBCardTitle>

                  <p href="#">Rs.28,000</p>
                  <Link to="/payment">
                    <MDBBtn>Pay Now</MDBBtn>
                  </Link>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={2} sm={4} md={3}>
              <MDBCard>
                <MDBCardImage src={ecommerce} position="top" alt="..." />
                <MDBCardBody>
                  <MDBCardTitle>E-Commerce App</MDBCardTitle>

                  <p href="#">Rs.35,000</p>
                  <Link to="/payment">
                    <MDBBtn>Pay Now</MDBBtn>
                  </Link>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={2} sm={4} md={3}>
              <MDBCard>
                <MDBCardImage src={education} position="top" alt="..." />
                <MDBCardBody>
                  <MDBCardTitle>Education App</MDBCardTitle>

                  <p href="#">Rs.45,000</p>
                  <Link to="/payment">
                    <MDBBtn>Pay Now</MDBBtn>
                  </Link>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={2} sm={4} md={3}>
              <MDBCard>
                <MDBCardImage src={food} position="top" alt="..." />
                <MDBCardBody>
                  <MDBCardTitle>Food App</MDBCardTitle>

                  <p href="#">Rs.15,000</p>
                  <Link to="/payment">
                    <MDBBtn>Pay Now</MDBBtn>
                  </Link>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={2} sm={4} md={3}>
              <MDBCard>
                <MDBCardImage src={healthcare} position="top" alt="..." />
                <MDBCardBody>
                  <MDBCardTitle>Health-Care App</MDBCardTitle>

                  <p href="#">Rs.85,000</p>
                  <Link to="/payment">
                    <MDBBtn>Pay Now</MDBBtn>
                  </Link>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={2} sm={4} md={3}>
              <MDBCard>
                <MDBCardImage src={service} position="top" alt="..." />
                <MDBCardBody>
                  <MDBCardTitle>Service App</MDBCardTitle>
                  <p href="#">Rs.10,000</p>
                  <Link to="/payment">
                    <MDBBtn>Pay Now</MDBBtn>
                  </Link>
                </MDBCardBody>
              </MDBCard>
            </Grid>

            <Grid item xs={2} sm={4} md={3}>
              <MDBCard>
                <MDBCardImage src={fintech} position="top" alt="..." />
                <MDBCardBody>
                  <MDBCardTitle>Fintech App</MDBCardTitle>

                  <p href="#">Rs.98,000</p>
                  <Link to="/payment">
                    <MDBBtn>Pay Now</MDBBtn>
                  </Link>
                </MDBCardBody>
              </MDBCard>
            </Grid>
          </Grid>
        </Box>
      </motion.div>
    </>
  );
};
