import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import logo from "../image/logo.jpeg";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
function Header() {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img className="logo" src={logo} alt="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Brand>
          <Link to="/">Coading & Services</Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link>
              <Link to="/">Home</Link>
            </Nav.Link>

            <NavDropdown title="Services" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">
                <Link to="/">Website & App Development</Link>
              </NavDropdown.Item>

              <NavDropdown.Item href="#action/3.1">
                Strategy Development
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Market Research and Analysis
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">SEO/GMB</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                PPC/Google Advertising
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Social Media Consulting
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Content Marketing Consulting
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Email Marketing Consulting
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Analytics and Performance Measurement
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Conversion Rate Optimization (CRO) Consulting
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Digital Branding and Reputation Management
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Content Marketing
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Graphic Designing
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Digital Marketing Courses
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link>
              <Link to="/team">Our Team</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="/awards">Award & Reward</Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="/about">About-us</Link>
            </Nav.Link>

            <Nav.Link>
              <Link to="/contact"> Contact-us</Link>
            </Nav.Link>

            <Nav.Link>
              <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <Link to="/contact" className="count">
                    <CountUp start={0} end={100} duration={2} delay={0} />+
                    Active Client
                  </Link>
                )}
              </ScrollTrigger>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
