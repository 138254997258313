import React from "react";

import vision from "../image/vision.png";
import purpose from "../image/purpose.gif";
import mission from "../image/mission.png";
import goal from "../image/goal.png";
import DrawerAppBar from "./Navbar";

import Footer from "./Footer";
import {
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import ServiceTables from "./services-2";
import { Grid } from "@mui/material";

export default function About() {
  return (
    <>
      <Grid container>
        <Grid item lg={6} sm={12}>
          <img className="img" src={vision} alt="vision" />
        </Grid>
        <Grid item lg={6} sm={12}>
          <p className="subheading">Vision.</p>
          <p>
            Choosing us for digital marketing ensures tailored strategies
            crafted to elevate your brand's online presence. Our team comprises
            seasoned experts adept in SEO, PPC, social media, and content
            marketing, delivering comprehensive solutions aligned with your
            goals. With a focus on innovation and data-driven insights, we
            optimize campaigns for maximum ROI and audience engagement. Our
            collaborative approach ensures transparency, timely communication,
            and flexibility to adapt to evolving market trends. Moreover, we
            prioritize client satisfaction, offering personalized attention and
            continuous support to drive sustainable growth and success.
            Partnering with us empowers your brand to thrive in the competitive
            digital landscape with confidence and distinction.
          </p>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item lg={6} sm={12}>
          <p className="subheading">Mission</p>
          <p>
            Choosing us for digital marketing ensures tailored strategies
            crafted to elevate your brand's online presence. Our team comprises
            seasoned experts adept in SEO, PPC, social media, and content
            marketing, delivering comprehensive solutions aligned with your
            goals. With a focus on innovation and data-driven insights, we
            optimize campaigns for maximum ROI and audience engagement. Our
            collaborative approach ensures transparency, timely communication,
            and flexibility to adapt to evolving market trends. Moreover, we
            prioritize client satisfaction, offering personalized attention and
            continuous support to drive sustainable growth and success.
            Partnering with us empowers your brand to thrive in the competitive
            digital landscape with confidence and distinction.
          </p>
        </Grid>
        <Grid item lg={6} sm={12}>
          <img className="img" src={mission} alt="mission" />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item lg={6} sm={12}>
          <img className="img" src={purpose} alt="goal" />
        </Grid>
        <Grid item lg={6} sm={12}>
          <p className="subheading">Purpose</p>
          <p>
            Choosing us for digital marketing ensures tailored strategies
            crafted to elevate your brand's online presence. Our team comprises
            seasoned experts adept in SEO, PPC, social media, and content
            marketing, delivering comprehensive solutions aligned with your
            goals. With a focus on innovation and data-driven insights, we
            optimize campaigns for maximum ROI and audience engagement. Our
            collaborative approach ensures transparency, timely communication,
            and flexibility to adapt to evolving market trends. Moreover, we
            prioritize client satisfaction, offering personalized attention and
            continuous support to drive sustainable growth and success.
            Partnering with us empowers your brand to thrive in the competitive
            digital landscape with confidence and distinction.
          </p>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item lg={6} sm={12}>
          <p className="subheading">Goal</p>
          <p>
            Choosing us for digital marketing ensures tailored strategies
            crafted to elevate your brand's online presence. Our team comprises
            seasoned experts adept in SEO, PPC, social media, and content
            marketing, delivering comprehensive solutions aligned with your
            goals. With a focus on innovation and data-driven insights, we
            optimize campaigns for maximum ROI and audience engagement. Our
            collaborative approach ensures transparency, timely communication,
            and flexibility to adapt to evolving market trends. Moreover, we
            prioritize client satisfaction, offering personalized attention and
            continuous support to drive sustainable growth and success.
            Partnering with us empowers your brand to thrive in the competitive
            digital landscape with confidence and distinction.
          </p>
        </Grid>
        <Grid item lg={6} sm={12}>
          <img className="img" src={goal} alt="goal" />
        </Grid>
      </Grid>

      <ServiceTables />
    </>
  );
}
