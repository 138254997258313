import React from "react";
import Header from "./Navbar";
import Footer from "./Footer";
import Heading from "./Heading";

const OurTeamPage = () => {
  return (
    <>
      <Header />
      <Heading />
      <Footer />
    </>
  );
};

export default OurTeamPage;
