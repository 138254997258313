import React from "react";
import { Navbar } from "react-bootstrap";
import Header from "./Navbar";
import Footer from "./Footer";
import { CenterFocusStrong } from "@mui/icons-material";

const Term_Condition = () => {
  return (
    <>
      <Header />
      <h3> Term & Condition</h3>
      <p>
        At PMSsale.com (“we,” “us,” “our”), we value your privacy and are
        committed to protecting your personal information. This Privacy Policy
        explains how we collect, use, disclose, and safeguard your personal data
        when you interact with our IT services and website. By accessing or
        using our services, you consent to the practices described in this
        policy.
      </p>
      <h3>1. Information We Collect</h3>
      <h4>
        We may collect the following types of personal information when you
        interact with us:
      </h4>
      <p>
        1.1. Personal Information: This may include your name, email address,
        phone number, postal address, and other contact information necessary
        for providing our services and communicating with you.
      </p>
      <p>
        1.2. Usage Data: We may collect information about how you use our
        website and services, including your IP address, browser type, device
        information, and pages visited. We use this data to improve our services
        and understand user behavior.
      </p>
      <p>
        1.3. Cookies and Similar Technologies: We may use cookies and similar
        tracking technologies to enhance your experience on our website and
        gather information about your preferences. You can manage your cookie
        preferences through your browser settings.
      </p>
      <h3>2. How We Use Your Information</h3>
      <p>We may use your personal information for the following purposes:</p>
      <p>
        2.1. Providing Services: To deliver our IT services, respond to your
        inquiries, and fulfill your requests.
      </p>
      <p>
        2.2. Communication: To communicate with you, send important updates,
        newsletters, and marketing materials, if you have opted in to receive
        them.
      </p>
      <p>
        2.3. Improving Services: To analyze usage patterns, conduct research,
        and enhance our website and services.
      </p>
      <p>
        2.4. Legal Compliance: To comply with applicable laws, regulations, and
        legal processes.
      </p>
      <h3>3. Data Sharing and Disclosure</h3>
      <p>We may share your personal information with the following parties:</p>
      <p>
        3.1. Service Providers: We may share your data with third-party service
        providers who assist us in delivering our services and improving our
        website.
      </p>
      <p>
        3.2. Legal Obligations: We may disclose your information when required
        by law or in response to legal requests, such as subpoenas or court
        orders.
      </p>
      <p>
        3.3. Business Transfers: If we are involved in a merger, acquisition, or
        sale of assets, your data may be transferred to the acquiring entity.
      </p>
      <p>
        3.4. Consent: We will obtain your explicit consent before sharing your
        information with third parties for marketing purposes.
      </p>
      <h3>4. Data Security</h3>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, disclosure, alteration, or destruction. However, no
        method of data transmission over the internet or electronic storage is
        entirely secure, and we cannot guarantee absolute security.
      </p>
      <h3>5. Your Rights</h3>
      <p>You have the following rights regarding your personal information:</p>
      <p>
        5.1. Access: You can request access to your personal information and
        obtain a copy of it.
      </p>
      <p>
        5.2. Correction: You can request corrections to inaccurate or incomplete
        personal information.
      </p>
      <p>
        5.3. Deletion: You can request the deletion of your personal
        information, subject to legal limitations.
      </p>
      <p>
        5.4. Opt-Out: You can opt-out of receiving marketing communications from
        us at any time.
      </p>
      <h3>6. Changes to this Privacy Policy</h3>
      <p>
        We may update this Privacy Policy periodically to reflect changes in our
        practices. The updated policy will be posted on our website, and the
        effective date will be revised accordingly.
      </p>
      <h3>7. Contact Us</h3>
      <p>
        If you have questions, concerns, or requests related to your privacy or
        this Privacy Policy, please contact us at pmsindia@pmssale.com. By using
        our services, you acknowledge that you have read and understood this
        Privacy Policy and agree to the collection, use, and sharing of your
        information as described herein.
      </p>

      <h3>8.Online Payment</h3>
      <p>
        By proceeding with the online payment, you agree to the following terms
        and conditions: Payments are processed securely via our authorized
        payment gateway. We accept major credit/debit cards and other specified
        methods. Transactions are encrypted to ensure data privacy. You confirm
        that the provided payment information is accurate and authorized. All
        sales are final, and refunds are subject to our refund policy.
        Unauthorized transactions may be subject to legal action. We reserve the
        right to cancel any order suspected of fraud. By completing the payment,
        you acknowledge and accept these terms and conditions.
      </p>
      <h5>
        <span>
          Note:At Online Payment Time, if Amount is Debited and Payment not
          receive by Company, will Refund in 7 days.
        </span>
      </h5>
    </>
  );
};

export default Term_Condition;
