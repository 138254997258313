import React, { useState } from "react";
import wp from "../image/wp.png";
import talk from "../image/lt.gif";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import ContactForm from "./Contact";
import Term_Condition from "./Term_Condition";
import { Link } from "react-router-dom";

export default function Footer() {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className="lead-gen" onClick={() => setVisible(!visible)}>
        <img src={talk} alt="wp" />
      </div>

      {visible ? (
        <div className="lead-gen">
          <ContactForm />
        </div>
      ) : (
        <></>
      )}

      <div className="wp-img">
        <a href="https://wa.me/9793504978" target="_blank">
          <img src={wp} alt="wp" />
        </a>
      </div>

      <div className="wp-img">
        <a href="https://wa.me/9793504978" target="_blank">
          <img src={wp} alt="wp" />
        </a>
      </div>
      <MDBFooter
        bgColor="light"
        className="text-center text-lg-start text-muted"
      >
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <h3>Imp(Term & Condition/Policies)*</h3>
            <p>
              <span>1-Dead-Line work done.</span>
            </p>
            <p>
              <span>2-Advance 30% must.</span>
            </p>
            <p>
              <span>3-100% payment of Completing of project.</span>
            </p>
            <p>
              <span>3- 5 changing Follow-Up.</span>
            </p>
            <p>
              <span>
                Note:At Online Payment Time, if Amount is Debited and Payment
                not receive by Company, will Refund in 7 days.
              </span>
              <p>
                <span>
                  <Link to={"/term_condition"}> More-Info...</Link>
                </span>
              </p>
            </p>
          </div>

          <div>
            <a href="" className="me-4 text-reset">
              <MDBIcon color="secondary" fab icon="facebook-f" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon color="secondary" fab icon="twitter" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon color="secondary" fab icon="google" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon color="secondary" fab icon="instagram" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon color="secondary" fab icon="linkedin" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon color="secondary" fab icon="github" />
            </a>
          </div>
        </section>

        <section className="">
          <MDBContainer className="text-center text-md-start mt-5">
            <MDBRow className="mt-3">
              <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <MDBIcon color="secondary" icon="gem" className="me-3" />
                  PMSsale.com
                </h6>
                <p>Our work is our face.</p>
              </MDBCol>

              <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Products</h6>
                <p>
                  <a href="#!" className="text-reset">
                    Angular
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    React
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Vue
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Laravel
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <a href="#!" className="text-reset">
                    Pricing
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Settings
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Orders
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Help
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <MDBIcon color="secondary" icon="home" className="me-2" />
                  00,siktaur,khorabar,gorakhpur,U.P(273010)
                </p>
                <p>
                  <MDBIcon color="secondary" icon="envelope" className="me-3" />
                  pmsindia@pmssale.com
                </p>
                <p>
                  <MDBIcon color="secondary" icon="phone" className="me-3" />
                  +91-9793504978
                </p>
                <p>
                  <MDBIcon color="secondary" icon="print" className="me-3" />
                  +91-9140470654
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          <a className="text-reset fw-bold" href="https://mdbootstrap.com/">
            PMSsale.com <Link to={"/term_condition"}> Term & Condition...</Link>
          </a>
        </div>
      </MDBFooter>
    </>
  );
}
