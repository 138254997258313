import React from "react";
import {
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { motion, useScroll } from "framer-motion";
import { useState } from "react";
export const Count = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <>
      <MDBContainer className="py-5">
        <motion.div
          className="card"
          initial={{
            opacity: 0,
            // if odd index card,slide from right instead of left
            x: 1 % 2 === 0 ? 50 : -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0, // Slide in to its original position
            transition: {
              duration: 1, // Animation duration
            },
          }}
          viewport={{ once: true }}
        >
          <MDBRow className="d-flex justify-content-center">
            <MDBCol md="10" xl="8" className="text-center">
              <h3 className="mb-4"> Customers Testimonials</h3>
              <p className="mb-4 pb-2 mb-md-5 pb-md-0">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                error amet numquam iure provident voluptate esse quasi,
                veritatis totam voluptas nostrum quisquam eum porro a pariatur
                veniam.
              </p>
            </MDBCol>
          </MDBRow>
        </motion.div>

        <MDBRow className="text-center">
          <MDBCol md="4" className="mb-5 mb-md-0">
            <div className="d-flex justify-content-center mb-4">
              <ScrollTrigger
                className="success-count"
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <CountUp start={0} end={9999} duration={2} delay={0} />
                )}
                <span>+</span>
              </ScrollTrigger>
            </div>
            <h5 className="mb-3">Clients</h5>

            <MDBTypography
              listUnStyled
              className="d-flex justify-content-center mb-0"
            ></MDBTypography>
          </MDBCol>
          <MDBCol md="4" className="mb-5 mb-md-0">
            <div className="d-flex justify-content-center mb-4">
              <ScrollTrigger
                className="success-count"
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <CountUp start={0} end={50} duration={2} delay={0} />
                )}
                <span>+</span>
              </ScrollTrigger>
            </div>
            <h5 className="mb-3">Services</h5>

            <MDBTypography
              listUnStyled
              className="d-flex justify-content-center mb-0"
            ></MDBTypography>
          </MDBCol>
          <MDBCol md="4" className="mb-5 mb-md-0">
            <div className="d-flex justify-content-center mb-4">
              <ScrollTrigger
                className="success-count"
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
              >
                {counterOn && (
                  <CountUp start={0} end={10} duration={2} delay={0} />
                )}
                <span>+</span>
              </ScrollTrigger>
            </div>
            <h5 className="mb-3">Countries</h5>

            <MDBTypography
              listUnStyled
              className="d-flex justify-content-center mb-0"
            ></MDBTypography>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};
