import React from "react";
import Heading from "./Heading";
import About from "./About";
import Footer from "./Footer";
import Header from "./Navbar";

export default function aboutPage() {
  return (
    <>
      <Header />
      <Heading />
      <About />
      <Footer />
    </>
  );
}
