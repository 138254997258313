import React, { useState } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarToggler,
  MDBNavbarLink,
  MDBContainer,
  MDBIcon,
  MDBCollapse,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Box, Button, Grid } from "@mui/material";
import { BuildTwoTone } from "@mui/icons-material";

export default function Heading() {
  const [showBasic, setShowBasic] = useState(false);

  return (
    <>
      <Box className="Heading-main">
        <Grid className="heading">
          Web/Android App & Digital Marketing Services
        </Grid>
        <p className="subheading">
          digital marketing services is the best one services.
        </p>
        <Button variant="outlined">More Info</Button>
      </Box>
    </>
  );
}
