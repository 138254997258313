import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function ServiceTables() {
  return (
    <TableContainer component={Paper} className="services-table">
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Digtal Consulting</StyledTableCell>
            <StyledTableCell align="left">Design & Development</StyledTableCell>
            <StyledTableCell align="left">Insight & Analysis</StyledTableCell>
            <StyledTableCell align="left">Virtual Classroom</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              SEO
              <br />
              Paid Advertising
              <br />
              Email Marketing
              <br />
              Social Media Marketing
              <br />
              Influencer Marketing <br />
              Reputation Marketing <br />
            </StyledTableCell>
            <StyledTableCell align="left">
              Mobile App
              <br />
              Game Development <br />
              Website
            </StyledTableCell>
            <StyledTableCell align="left">
              Customer Data
              <br />
              Market Study
            </StyledTableCell>
            <StyledTableCell align="left">
              Login For School Admin,Teacher,Parents/Students
              <br />
              Multiple Classrooms Simultaneously
              <br />
              Live Video
              <br />
              Share Presentation,Pdfs,Videos
              <br />
              White Board During The Class
              <br />
              Data Monitoring For Student/Teachers Usage
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
