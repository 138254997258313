import React, { useState } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarToggler,
  MDBNavbarLink,
  MDBContainer,
  MDBIcon,
  MDBCollapse,
  MDBBtn,
} from "mdb-react-ui-kit";
import Login from "./Login";
import Sub_heading from "./Sub-heading";
import Pca from "./Pca";
import SupportImage from "./SupportImage";
import Benefits from "./Benefits";
import SocialProof from "./SocialProof";
import Navigation from "./Navigation";
import ContentOffer from "./ContentOffer";
import Sca from "./Sca";
import Features from "./Features";
import Resources from "./Resources";
import Heading from "./Heading";
import SuccessIndicates from "./SuccessIndicates";
import { motion } from "framer-motion";
import DrawerAppBar from "./Navbar";
import Footer from "./Footer";
import ContactForm from "./Contact";
import SignUp from "./SignUp";
import Services from "./Services";
import ProfilePage from "./UserProfile";
import BookNow from "./BookNow";
import { WhatIsDigitalMarketing } from "./WhatIsDigitalMarketing";
import { Count } from "./Count";
import { ProductCard } from "./ProductCard";
import { dmPoster } from "./dm";
import DmPoster from "./DmPoster";
export default function App() {
  return (
    <>
      <DrawerAppBar />

      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          x: 1 % 2 === 0 ? 50 : -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0, // Slide in to its original position
          transition: {
            duration: 1, // Animation duration
          },
        }}
        viewport={{ once: true }}
      >
        <Heading />
      </motion.div>
      <DmPoster />
      <ProductCard />

      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          x: 2 % 2 === 0 ? 50 : -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0, // Slide in to its original position
          transition: {
            duration: 1, // Animation duration
          },
        }}
        viewport={{ once: true }}
      >
        <WhatIsDigitalMarketing />
      </motion.div>

      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          x: 3 % 2 === 0 ? 50 : -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0, // Slide in to its original position
          transition: {
            duration: 1, // Animation duration
          },
        }}
        viewport={{ once: true }}
      >
        <Pca />
      </motion.div>

      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          x: 3 % 2 === 0 ? 50 : -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0, // Slide in to its original position
          transition: {
            duration: 1, // Animation duration
          },
        }}
        viewport={{ once: true }}
      >
        <ContactForm />
      </motion.div>
      <Count />
      <Footer />
    </>
  );
}
