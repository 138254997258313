import React from "react";
import Header from "./Navbar";
import Heading from "./Heading";
import Footer from "./Footer";
import ContactForm from "./Contact";

export default function contactPage() {
  return (
    <>
      <Header />
      <Heading />
      <ContactForm />
      <Footer />
    </>
  );
}
